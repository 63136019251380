import LoginContent from '../components/login/LoginContent';
import LoginHead from '../components/login/LoginHead';

export default function Login() {
  return (
    <>
      <LoginHead />
      <LoginContent />
    </>
  );
}