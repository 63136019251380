const apiSecret = 'c1f3b9a87d6e4f5db2a39e1d8c64ea6b';

export let config = {
    formKey: '566e2acc-e586-41b4-afc6-e1d4a4f4b743',
    openWeatherKey: 'f36a2ddc5e33d14d3708b92f20f1d315',
    lat: 45.8708907,
    lon: -1.2491633,
    mapId: '15019cba5d15b2ed',
    location: 'Oléron Villa',
    tideURL: `https://zapphyqkan3dd6m6zdmpnnjrei0olqbg.lambda-url.eu-west-1.on.aws?secret=${apiSecret}`,
    getWeeksURL: `https://3kfae6izgkgcfotbonpndsxabu0buefu.lambda-url.eu-west-1.on.aws?secret=${apiSecret}`,
    getBookingsURL: `https://yhddslklwa44u65uyxgde4hdcu0bnodu.lambda-url.eu-west-1.on.aws?secret=${apiSecret}`,
    updateBookingURL: `https://jenbcvq4j2saup4cjrqttr4u4m0eiyht.lambda-url.eu-west-1.on.aws?secret=${apiSecret}`,
}